import { css } from "@emotion/react";
import leftCurves from "../../../images/left_curves.svg";
import leftCurves2mobile from "../../../images/fraud/left_curve_2_mobile.svg";
import media from "../../breakpoints";

const MainSectionWrapper = css`
  overflow: hidden;
  .wrapper-parent {
    min-height: 650px;
  }
  .curves-parent {
    ${media("md")} {
      background: url(${leftCurves2mobile}) center no-repeat;
      width: 1852px;
      height: 609px;
    }
    background: url(${leftCurves}) center no-repeat;
    width: 1865px;
    height: 822px;
    transform: translate(-130%, -20%);
  }
  .title-lg-parent {
    max-width: 550px;
  }
  .title-parent {
    max-width: 520px;
  }
  .btn-parent {
    max-width: 179px;
  }
  .logos-parent {
    max-width: 1070px;
    .gatsby-image-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto auto auto 0;
    }
  }
  .image-parent {
    .gatsby-image-wrapper {
      max-width: 450px;
    }
  }

  @media screen and (max-width: 1440px) {
    .curves-parent {
      transform: translate(-96%, -20%);
    }
  }

  @media screen and (max-width: 1279.98px) {
    .image-parent svg {
      max-width: 100%;
    }
  }

  @media screen and (max-width: 767.98px) {
    .curves-parent {
      transform: translate(-60%, 30%);
    }
  }

  @media screen and (max-width: 480px) {
    .curves-parent {
      transform: translate(-50%, 50%);
    }
  }
`;

export { MainSectionWrapper };
