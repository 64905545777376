import React from "react";
import { MainSectionWrapper } from "./featureSection.styles";
import { GradientList } from "../../gradientList/gradientList";

const features = [
  "Fraudsters are 10x more likely to use throw-away emails",
  "Remote desktop tech support scam using Teamviewer",
  "Context switching, distracted filling out forms.",
  "Detect mobile phone emulator farm.",
  "Zero-day proxy VPN detection.",
  "Long-term memory field copy and paste.",
  "8x more likely to use pre-paid or VoIP phone.",
  "Expert user mouse or scrolling patterns.",
  "AI-based browser fingerprinting.",
  "TrueOS mismatch between user and app.",
  "Phone and email mapping to bank accounts.",
  "One device linked to multiple accounts.",
];

const MainSection = () => (
  <section className="relative section-wrapper" css={MainSectionWrapper}>
    <div className="relative w-full pt-24 bg-transparent wrapper-parent layout-width">
      <div className="flex flex-col items-center mb-44 lg:mb-20 md:mb-16">
        <p className="content-start mb-4 heading-m md:heading-s text-center text-blue-darken md:mb-5">
          Identity, behavior and device intelligence is at the core of our
          platform.
        </p>
        <p className="mb-20 text-base text-center font-Inter title-parent text-blue-darken md:mb-12">
          Our team’s combined experience spans decades of fighting fraud at the
          world’s top neobanks, crypto exchanges, and consumer brands.
        </p>
        <GradientList items={features} />
      </div>
    </div>
  </section>
);

export default MainSection;
