import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo/seo";
import MainSection from "../components/fraudSections/mainSection/mainSection";
import StopSection from "../components/fraudSections/stopSection/stopSection";
import FeatureSection from "../components/fraudSections/featureSection/featureSection";
import BlueSection from "../components/blueSetion/blueSection";

const FraudPage = () => {
  const textArr = ["Move money", " fast, ", "without risk."];

  return (
    <Layout>
      <Seo title="Fraud" pathname="/fraud" />
      <main>
        <MainSection />
        <StopSection />
        <FeatureSection />
        <BlueSection size="sm" textArr={textArr} btnText={"Get in touch"} />
      </main>
    </Layout>
  );
};

export default FraudPage;
