import React, { FC } from "react";
import {
  MainSectionWrapper,
  MobileMainImageContainer,
} from "./mainSection.styles";
import straight from "../../../images/straight.svg";
import CtaList, { CtaListProps } from "../../ctaList/ctaList";
import MobileMainImageAddon from "../../../images/fraud/neobank_mobile_signup_addon.inline.svg";
import MobileMainImage from "../../../images/fraud/neobank_mobile_signup.inline.svg";
import MainImage from "../../../images/fraud/Fraud_1.inline.svg";

const listData: CtaListProps = {
  title: ["Stop fraud during", "account creation"],
  subTitle:
    "Integrate Sardine’s SDK to prevent fraud at the time of new user sign-up, on-boarding, or account creation. ",
  items: [
    {
      title: "Low-friction identity verification",
      subTitle:
        "Perform advanced SSN checks to flag stolen or synthetic identities. ",
      icon: straight,
    },
    {
      title: "Continuously improving behavior biometrics",
      subTitle:
        "Monitor for suspicious fraudster “fingerprints”, when filling out forms.",
      icon: straight,
    },
    {
      title: "World’s most advanced device intelligence",
      subTitle: "Stop worrying about fraud",
      icon: straight,
    },
  ],
};

const MainSection = () => (
  <section className="relative section-wrapper" css={MainSectionWrapper}>
    <div className="absolute top-0 curves-parent left-full"></div>
    <div className="relative w-full pt-24 bg-transparent wrapper-parent layout-width">
      <div className="flex flex-row flex-wrap justify-between h-full">
        <div className="flex justify-center w-1/2 sm:w-full">
          <CtaList {...listData} />
        </div>
        <div className="flex flex-col justify-center w-1/2 mx-auto image-parent sm:w-full">
          <div className="mx-auto">
            <MainImage className="md:hidden block" />
            <MobileMainImageContainer className="relative hidden md:block mt-5">
              <MobileMainImage />
              <MobileMainImageAddon className="absolute addon" />
            </MobileMainImageContainer>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default MainSection;
