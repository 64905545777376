import { css } from "@emotion/react";
import mainCurves from "../../../images/main_curves.svg";

const MainSectionWrapper = css`
  overflow: hidden;
  .wrapper-parent {
    min-height: 753px;
  }
  .curves-parent {
    background: url(${mainCurves}) center no-repeat;
    width: 1624px;
    height: 542px;
    transform: translate(-79%, 0);
  }
  .title-lg-parent {
    max-width: 902px;
  }
  .title-parent {
    /* max-width: 591px; */
    max-width: 549px;
    text-align: justify;
  }
  .btn-parent {
    max-width: 179px;
  }
  .logos-parent {
    max-width: 1070px;
    .gatsby-image-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto auto auto 0;
    }
  }
  .image-parent .gatsby-image-wrapper {
    max-width: 450px;
  }
`;

export { MainSectionWrapper };
