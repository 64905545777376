import stylyed from "@emotion/styled";
import media from "../breakpoints";

export const Item = stylyed.div`
  background: radial-gradient(155.21% 2889.33% at -18.36% 8.93%, #3147FF 0%, #161B33 100%);
  &:nth-of-type(3n) {
    margin-right:0
  }
  ${media("md")} {
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
  }
`;
