import React, { FC } from "react";
import { MainSectionWrapper } from "./stopSection.styles";
import straight from "../../../images/straight.svg";
import CtaList, { CtaListProps } from "../../ctaList/ctaList";
import MainImage from "../../../images/fraud_2.inline.svg";

const listData: CtaListProps = {
  title: ["Stop digital wallet", "payment fraud"],
  subTitle:
    "Offer more payment options and features, without worrying about fraud.",
  items: [
    {
      title: "Stop wallet funding by stolen card or bank accounts",
      subTitle:
        "Consortium of millions of card, neobank users, and BIN blocklists.",
      icon: straight,
    },
    {
      title: "Safeguard payments, remittance, and crypto buys",
      subTitle:
        "AI-based mapping of identity signals to bank and blockchain data.",
      icon: straight,
    },
    {
      title: "Stop unauthorized card purchases",
      subTitle: "Real-time monitoring to avoid card issuing fraud.",
      icon: straight,
    },
  ],
  link: {
    text: "See a demo",
    url: "/contact",
  },
};

const MainSection: FC = () => (
  <section
    className="relative overflow-visible section-wrapper"
    css={MainSectionWrapper}
  >
    <div className="absolute top-0 curves-parent left-full"></div>
    <div className="relative w-full pt-24 bg-transparent wrapper-parent layout-width">
      <div className="flex flex-row-reverse flex-wrap justify-between h-full lg:mb-20">
        <div className="flex justify-center w-1/2 sm:w-full">
          <CtaList {...listData} />
        </div>
        <div className="flex flex-col justify-center w-1/2 mx-auto image-parent sm:w-full">
          <div className="mx-auto">
            <MainImage />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default MainSection;
