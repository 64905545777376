import classnames from "classnames";
import { navigate } from "gatsby";
import React from "react";

export interface CtaItem {
  title: string;
  subTitle: string;
  icon: string;
}

export interface CtaLink {
  text: string;
  url: string;
}

export interface CtaListProps {
  title: string | string[]; // use string array when you want to enforce line-breaks
  subTitle?: string;
  items?: CtaItem[];
  link?: CtaLink;
}

const renderList = (items: CtaItem[], hasBtnCta: boolean) => {
  return (
    <div
      className={classnames(
        "flex flex-col justify-center w-full font-Inter list-parent",
        { "mb-6": hasBtnCta }
      )}
    >
      {items &&
        items.map((item, idx) => (
          <div style={{ maxWidth: "470px" }} key={idx}>
            <div className="flex flex-row items-center justify-start w-full mb-6 bg-transparent font-Inter ralative">
              <img src={item.icon} alt={item.title} />
              <div className="flex flex-col justify-start ml-6">
                <p className="mb-1 text-base font-semibold font-Inter text-blue-darken">
                  {item.title}
                </p>
                <div className="text-body-s text-blue-darken">
                  {item.subTitle}
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

const renderBtnCta = (link: CtaLink) => {
  return (
    <button
      className="h-12 px-8 ml-0 text-base font-semibold text-white font-Inter btn-parent bg-blue-accent rounded-2xl"
      onClick={() => (link.url ? navigate(link.url) : void 0)}
    >
      {link.text}
    </button>
  );
};

const toComponent = (title: string[]) =>
  title.map((t, i) => (
    <React.Fragment key={i}>
      {t}
      {i < title.length - 1 ? <br /> : ""}
    </React.Fragment>
  ));

const CtaList = ({ items, subTitle, title, link }: CtaListProps) => {
  return (
    <div className="flex flex-col justify-between">
      <div className="flex flex-col justify-center mb-16 md:mb-0">
        <span
          className={classnames(
            "heading-m md:heading-s content-start text-blue-darken",
            { "mb-4 md:mb-5": Boolean(subTitle), "mb-8": Boolean(!subTitle) }
          )}
        >
          {Array.isArray(title) ? toComponent(title) : title}
        </span>
        {subTitle && (
          <p
            className={`text-base font-Inter title-parent text-blue-darken ${
              items?.length ? "mb-8" : ""
            }`}
          >
            {subTitle}
          </p>
        )}
        {items && renderList(items, Boolean(link))}
        {link && renderBtnCta(link)}
      </div>
    </div>
  );
};

export default CtaList;
