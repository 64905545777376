import React from "react";
import cyanDot from "../../images/cyan-dot.svg";
import { Item } from "./gradientList.styles";

export interface GradientListProps {
  items: string[];
}

export const GradientList = ({ items }: GradientListProps) => {
  return (
    <div className="flex flex-wrap justify-center">
      {items.map((item, idx) => (
        <Item
          className="flex items-center py-6 pl-6 pr-5 mb-4 mr-8 font-medium text-white md:mr-0 rounded-2xl md:w-full md:font-normal w-1/4 md:w-full"
          key={idx}
        >
          <img src={cyanDot} alt="dot" />
          <div className="pl-2 font-Roboto">{item}</div>
        </Item>
      ))}
    </div>
  );
};
